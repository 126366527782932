<template>
  <Yumpu v-if="$route.meta.yumpu_embeded_id[$lang]" :embed_id="$route.meta.yumpu_embeded_id[$lang]" />
  <div v-else class="not_found"></div>
</template>

<script>
import Yumpu from "@/components/Elements/Yumpu"
export default {
name: "YumpuView",
  components: {Yumpu}
}
</script>

<style scoped>
.not_found {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #FEFDEB;
}
</style>